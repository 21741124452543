import { useParams, useSearchParams } from "react-router-dom";
import { QueryParams } from "./QueryParams";
import { RoutingParams } from "./RoutingParams";
import { TargetRoutingParams } from "./TargetRoutingParams";

function App() {
  const baseRedirectUrl =
    process.env.NODE_ENV === "production"
      ? "https://alpha.safesend.app"
      : "https://localhost:3001";

  const params = useParams();
  const [queryParams] = useSearchParams();
  const redirectSearchParams = new URLSearchParams();

  redirectSearchParams.append(
    RoutingParams.RECIPIENT,
    params[RoutingParams.RECIPIENT] ?? ""
  );

  const addRedirectQueryParam = (param?: string) => {
    if (param && queryParams.get(param))
      redirectSearchParams.append(param, queryParams.get(param) ?? "");
  };

  [QueryParams.AMOUNT, QueryParams.CURRENCY].forEach(addRedirectQueryParam);

  const redirectUrl = `${baseRedirectUrl}/#/${TargetRoutingParams.SEND}?${redirectSearchParams}`;

  window.location.href = redirectUrl;

  return null;
}

export default App;
